<template>
    <div class="container my-5" v-if="user">
        <h4>Profile ({{ isMaterialTester ? "Material Inspector" : "Buyer/Seller" }})</h4>
        <hr />
        <div class="row">
            <div class="col-md-6">
                <ProfileInfoItem label="Name" :value="user.name" />
                <ProfileInfoItem label="Email" :value="user.email" />
                <ProfileInfoItem v-if="getState" label="State" :value="getState" />
                <ProfileInfoItem v-if="getCity" label="City" :value="getCity" />
            </div>
            <div class="col-md-6">
                <img :src="imgsrc + user.profile_img" class="img-fluid" alt="material inspector" />
            </div>
        </div>

        <div class="my-2 mt-4">
            <RatingButton v-if="user" :forUserId="user.id" />
            <Ratings :forUserId="user.id" :materialTester="user" />
        </div>
    </div>
</template>

<script>
import scrollTop from "../utils/scrollTop"
import request from "../../apis/request"
import { mapState, mapGetters } from "vuex"
import ProfileInfoItem from "./ProfileInfoItem.vue"
import RatingButton from "../Rating/RatingButton"
import Ratings from "../Rating/Ratings.vue"
import axios from "axios"

export default {
    async mounted() {
        scrollTop()
        await this.fetchUserDetails()
    },
    components: {
        ProfileInfoItem,
        RatingButton,
        Ratings,
    },
    computed: {
        getCity() {
            return this.user.city && this.user.city.city_name
        },
        getState() {
            return this.user.state && this.user.state.state_name
        },
        isMaterialTester() {
            if (this.user) {
                return Number(this.user.role_id) === 3
            }
            return false
        },
    },
    data() {
        return {
            user: null,
            imgsrc: axios.defaults.uploadURL,
        }
    },
    methods: {
        async fetchUserDetails() {
            console.log("fetching user details")
            const userId = this.$route.params.id
            const res = await request.get("/user-details/" + userId)
            const data = res.data
            console.log("result of fetching user", data)
            this.user = data.result
        },
    },
}
</script>

<style></style>
